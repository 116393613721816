import {
  Accordion,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import keyBy from 'lodash/keyBy';
import React from 'react';

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: 'right' },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
});

export const ListItems = (props) => {
  const classes = useStyles();

  const getItemIds = (items) => {
    let newArrayItems = [];
    items.map((item) => {
      return newArrayItems.push(item.id);
    });
    return newArrayItems;
  };

  const itemsIds = getItemIds(props.order.items);

  function uniqueArray(value) {
    return [...new Set(value)];
  }
  function getIdenticalItems(array, value) {
    return array.filter((v) => v === value);
  }
  const uniqueItemsIds = uniqueArray(itemsIds);

  const uniqueArrayItems = keyBy(props.order.items, 'id');

  const areTokensRedeeemed =
    Object.keys(props.order.tokens_redeemed || {}).length > 0;
  return (
    props.order.items && (
      <Accordion defaultExpanded={props.defaultExpand === false ? false : true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" gutterBottom>
            Items
          </Typography>
        </AccordionSummary>
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  Price
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  Quantity
                </TableCell>
                {areTokensRedeeemed && (
                  <TableCell className={classes.rightAlignedCell}>
                    Tokens Redeemed
                  </TableCell>
                )}
                <TableCell className={classes.rightAlignedCell}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueItemsIds.map((item) => {
                const identicalItems = getIdenticalItems(
                  itemsIds,
                  uniqueArrayItems[item].id
                );
                const itemQuantity = identicalItems.length;
                const tokensRedeemed = props.order.items.filter(
                  (t) => t.redeemed_token_id
                ).length;
                return (
                  <TableRow key={uniqueArrayItems[item].id}>
                    <TableCell>{uniqueArrayItems[item].short_name}</TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                      {`$ ${Number.parseFloat(
                        (uniqueArrayItems[item].price / 100).toFixed(2)
                      )}`}
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                      {itemQuantity}
                    </TableCell>
                    {areTokensRedeeemed && (
                      <TableCell className={classes.rightAlignedCell}>
                        {tokensRedeemed}
                      </TableCell>
                    )}
                    <TableCell className={classes.rightAlignedCell}>
                      {`$ ${Number.parseFloat(
                        ((itemQuantity - tokensRedeemed) *
                          uniqueArrayItems[item].price) /
                          100
                      ).toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Accordion>
    )
  );
};
