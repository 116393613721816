import { gql } from 'graphql-request';

export const RECEIPT_ONE = gql`
  query receipt_one($reference_id: String!) {
    orders(where: { reference_id: {_eq: $reference_id }}) {
      items
      reference_id
      subtotal
      tax
      tip
      status
      
      transaction_at
      transaction_time
      id
      event {
        name
        digital_surcharge_label
      }
      tokens_redeemed
      discount
      location {
        name
      }
      digital_surcharge
    }
  }
`;
