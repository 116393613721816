import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { ListItems } from '../components/item-list';
import { OrderInfo } from '../components/order-info';
import { TotalList } from '../components/totals';
import { useQueryCustom } from '../hooks/query-receipt';
import { RECEIPT_ONE } from '../query/receipt-one.query';

export const Receipt = (props) => {
  // eslint-disable-next-line
  const [refVariables, setRefVariables] = useState({
    reference_id: props.match.params.id,
  });

  // eslint-disable-next-line
  const { data, status, error, total } = useQueryCustom({
    graphqlQuery: RECEIPT_ONE,
    variables: refVariables,
  });
  
  
  const order = data?.orders ? data.orders[0] : {};
  
  return data ? (
    <Box maxWidth="40em" margin="2.5em auto">
      <Container>
        <OrderInfo data={order} />
        <ListItems order={order}/>
        <TotalList data={order} />
      </Container>
    </Box>
  ) : null;
};
