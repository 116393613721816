import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

export const OrderInfo = ({ data, ...props }) => {
  // eslint-disable-next-line

  const Spacer = () => <Box m={1}>&nbsp;</Box>;
  const dateTimeString = `${new Date(data.transaction_time).toDateString()}, ${new Date(data.transaction_time).toLocaleTimeString()}`
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Typography variant="h4" gutterBottom>
            Receipt #{data.id}
          </Typography>
        </Grid>
        <Spacer />

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '24px',
          }}
        >
          <Grid container item xs={12} sm={12} md={12}>
            <Grid container direction="column">
              <TextField
                id="reference"
                label="Reference"
                defaultValue={data?.reference_id}
                InputProps={{
                  readOnly: true,
                  classes,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12}>
            <Grid container direction="column">
              <TextField
                id="status"
                label="Status"
                defaultValue={data?.status}
                InputProps={{
                  readOnly: true,
                  classes,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12}>
            <Grid container direction="column">
              <TextField
                id="time-of-the-transaction"
                label="Date"
                defaultValue={dateTimeString}
                InputProps={{
                  readOnly: true,
                  classes,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12}>
            <Grid container direction="column">
              <TextField
                id="event"
                label="Event"
                defaultValue={data.event?.name}
                InputProps={{
                  readOnly: true,
                  classes,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12}>
            <Grid container direction="column">
              <TextField
                id="location"
                label="Location"
                defaultValue={data.location?.name}
                InputProps={{
                  readOnly: true,
                  classes,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};
