import {
  Accordion,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: 'right' },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
});

export const TotalList = (props) => {
  const classes = useStyles();

  let amountRefunded = 0
  if (props?.data?.transactions?.length > 0) {
    props.data.transactions?.map((transaction) => {
      let amountRefund = transaction?.status === 'refund' ? transaction.amount : 0
      amountRefunded += amountRefund
    })
  }
    
  let cashBalanceCharged = 0;
  let subtotal = 0;
  let tax = 0;
  let redeemedTokenValue = 0;
  let discountApplied = 0;
  let surchargeAmount = 0;
  let tokens_redeemed = {...props?.data?.tokens_redeemed || {}}
  props.data.items.forEach((item, index) => {
    
    if (index === 0) {
      cashBalanceCharged += item?.cashBalanceCharged || 0;
      cashBalanceCharged += item?.promoBalanceCharged || 0; ;
    }
    if (!isNaN(item?.item_tax)) {
      if(item.redeemed_token_id) {
        redeemedTokenValue += item.price
        tokens_redeemed[item.redeemable_token_id] = {
          ...tokens_redeemed[item.redeemable_token_id],
          redeemable_token_name: item.redeemable_token_name
        }
        
      }
      if (item.hasOwnProperty("item_discount_applied")) {
        discountApplied += item.item_discount_applied
      }
      tax = props.data.tax; 
    } else {
      tax = props.data.tax; 
    }
    // If order.item.surchargeAmount, a new surcharge has been calculated based on rfid_asset cash balances
    // order.item.surchargeAmount = (order.total_before_surcharge - cash balances applied)
    // Use this value instead of order.digital_surcharge
    if (item.hasOwnProperty("surchargeAmount")) {
      surchargeAmount = (item.surchargeAmount || 0);
    } else {
      surchargeAmount = (props.data.digital_surcharge || 0);
    }
    subtotal = props.data.subtotal;
  })

  let total = (subtotal - redeemedTokenValue - discountApplied - amountRefunded) + (tax + props.data.tip + surchargeAmount) -  cashBalanceCharged

  return (
    <Accordion defaultExpanded={props.defaultExpand === false ? false : true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" gutterBottom>
          Total Summary
        </Typography>
      </AccordionSummary>
      <Paper className={classes.root}>
        <Table>
          <TableHead></TableHead>

          <TableBody>
            <TableRow key="subtotal">
              <TableCell>Subtotal</TableCell>

              <TableCell className={classes.rightAlignedCell}>
                {`$ ${Number.parseFloat(subtotal / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
            {redeemedTokenValue > 0 && 
              <TableRow key="subtotal">
                <TableCell>Redeemed Token Value</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                {`$${(redeemedTokenValue / 100).toFixed(2)}`}
                </TableCell>
              </TableRow>
            }
            {discountApplied > 0 ?
            <TableRow key="subtotal">
              <TableCell>Discount </TableCell>

              <TableCell className={classes.rightAlignedCell}>
              {`-$${(discountApplied / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
            : null}
            <TableRow key="Service Fees">
              <TableCell>{props.data.event?.digital_surcharge_label}</TableCell>
              <TableCell className={classes.rightAlignedCell}>
                {`$ ${Number.parseFloat(
                  props.data.digital_surcharge / 100
                ).toFixed(2)}`}
              </TableCell>
            </TableRow>
            <TableRow key="tip">
              <TableCell>Tip</TableCell>

              <TableCell className={classes.rightAlignedCell}>
                {`$ ${Number.parseFloat(props.data.tip / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
            {cashBalanceCharged > 0 &&
              <TableRow key="tax">
              <TableCell>Cash Balance Charged</TableCell>
              <TableCell className={classes.rightAlignedCell}>
                {`$ ${Number.parseFloat(cashBalanceCharged / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
            }
            <TableRow key="tax">
              <TableCell>Tax</TableCell>

              <TableCell className={classes.rightAlignedCell}>
                {`$ ${Number.parseFloat(tax / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
            {amountRefunded !== 0 ? (
              <TableRow key="Refund">
                <TableCell>Refund</TableCell>

                <TableCell className={classes.rightAlignedCell}>
                  {`$ ${Number.parseFloat(
                    amountRefunded / 100
                  ).toFixed(2)}`}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow key="total">
              <TableCell>
                <strong>Total</strong>
              </TableCell>

              <TableCell className={classes.rightAlignedCell}>
                <strong>
                  {`$${(total / 100).toFixed(2)}`}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Accordion>
  );
};
