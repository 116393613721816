import { GraphQLClient } from 'graphql-request';
import { useEffect, useReducer } from 'react';

const HASURA_ENDPOINT = process.env.REACT_APP_SERVICE_HASURA_ENDPOINT;

const reducer = (state, action) => {
  switch (action.type) {
    case 'idle':
      return {
        status: 'idle',
        data: undefined,
        error: undefined,
        total: undefined,
      };
    case 'loading':
      return {
        status: 'loading',
        data: undefined,
        error: undefined,
        total: undefined,
      };
    case 'success':
      return {
        status: 'success',
        data: action.payload,
        error: undefined,
        total: undefined,
      };
    case 'error':
      return {
        status: 'error',
        data: undefined,
        error: action.payload,
        total: undefined,
      };
    default:
      throw new Error('invalid action');
  }
};

export function useQueryCustom({ graphqlQuery, variables }) {
  const initialState = {
    status: graphqlQuery ? 'loading' : 'idle',
    data: undefined,
    error: undefined,
    total: undefined,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (variables !== undefined) {
      dispatch({ type: 'loading' });

      const graphQLClient = new GraphQLClient(HASURA_ENDPOINT);

      const result = graphQLClient
        .request(graphqlQuery, variables)
        .then((data) => dispatch({ type: 'success', payload: data }))
        .catch((error) => dispatch({ type: 'error', payload: error }));

      return result;
    }
    // eslint-disable-next-line
  }, [variables]);

  return state;
}
