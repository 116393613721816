import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BadRequest } from './pages/404';
import { Receipt } from './pages/receipt';

export function App(props) {
  return (
    <Router>
      <div>
        <Switch>
          <Route
            path="/receipt/:id"
            render={(props) => <Receipt {...props} />}
          ></Route>
          <Route path="/">
            <BadRequest />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
